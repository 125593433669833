<template>
  <div class="menu-container">
    <Menubar :model="items" class="custom-menubar">
      <template #start>
        <img
          src="@/assets/electro3.png"
          alt="Electro3 Logo"
          height="40"
          class="img-logotipo"
          @click="redirectToHome"
        />
      </template>
    </Menubar>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";

export default {
  components: {
    Menubar,
  },
  data() {
    return {
      currentRoute: this.$route.path, // Track the current route
      items: [
        {
          label: "HOME",
          command: () => {
            this.navigateTo("/");
          },
        },
        {
          label: "SOBRE NOSOTROS",
          command: () => {
            this.navigateTo("/SobreNosotros/");
          },
        },
        {
          label: "PARTNERS",
          command: () => {
            this.navigateTo("/NuestrosPartners");
          },
        },
        {
          label: "NUESTROS SERVICIOS",
          command: () => {
            this.navigateTo("/NuestrosServicios/");
          },
        },
        {
          label: "VARTA",
          command: () => {
            window.location.href = 'https://varta.com.ar';
          },
        },
        {
          label: "CONTACTO",
          command: () => {
            this.navigateTo("/Contacto");
          },
        },
        // {
        //   label: 'IDENTIFICARSE',
        //   command: () => { this.navigateTo('/'); },   Funcionalidad en desarrollo.
        // },
      ],
    };
  },
  methods: {
    redirectToHome() {
      this.$router.push("/");
      this.currentRoute = "/";
    },
    navigateTo(path) {
      this.$router.push(path);
      this.currentRoute = path;
    },
  },
};
</script>

<style scoped>
.img-logotipo {
  width: 25rem; /* Asegura que ocupe el 100% del ancho del contenedor */
  height: 6rem; /* Define una altura fija o ajusta según lo que necesites */
  margin-left: 5rem;
  margin-top: 0rem;
  margin-right: 10rem;
  cursor: pointer;
}

.menu-container {
  margin-top: 4rem; /* Elimina márgenes */
  padding: 0; /* Elimina paddings */
  width: 100%; /* Asegura que ocupe el 100% del ancho del contenedor */
  height: 100px; /* Define una altura fija o ajusta según lo que necesites */
  background-image: url("@/assets/menu.png"); /* Ruta a la imagen */
  background-size: 140rem 40rem; /* Fuerza que la imagen ocupe el 100% del ancho y alto del contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-position: center; /* Centra la imagen dentro del contenedor */
  box-sizing: border-box; /* Asegura que el padding y borde no afecten el tamaño del contenedor */
}

.custom-menubar {
  --p-menubar-item-color: rgb(250, 250, 250);
  --p-menubar-item-focus-background: transparent;
  --p-menubar-item-focus-color: rgb(10, 10, 10);
  border: none !important;
  box-shadow: none;
  background: transparent !important;
  padding: 0; /* [ADD] Evita espaciado que desestructura el tamaño del menu */
  height: 100%; /* [ADD] Utiliza la altura máxima del contenedor */
}

@media (max-width: 1600px) {
  .menu-container {
    height: 90px;
  }
  .img-logotipo {
    width: 20rem;
    height: 5rem;
    margin-right: 7rem;
  }
}

@media (max-width: 1310px) { /* Diseño responsive */
  .menu-container {
    height: 80px;
  }
  .img-logotipo {
    width: 15rem;
    height: 5rem;
    margin-left: 1rem;
    margin-right: 3rem;
  }
}

@media (max-width: 768px) {
  .menu-container {
    width: 100%;
  }
}
</style>

<style> /* Estilos globales */
.custom-menubar {
  justify-content: space-between;
}

.menu-container .p-menubar-mobile .p-menubar-button {
  color: #000;
  border: solid 1px #000;
  margin-right: .3em;
}
</style>