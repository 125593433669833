<template>
  <div class="menu-container">
    <Menubar :model="items" class="custom-menubar">
      <template #start>
        <img src="@/assets/Varta/VartaLogo.png" alt="Varta Logo" height="40" class="p-mr-2" @click="redirectToVarta" />
      </template>
    </Menubar>
  </div>
</template>

<script>
import Menubar from 'primevue/menubar';

export default {
  components: {
    Menubar,
  },
  data() {
    return {
      currentRoute: this.$route.path, // Track the current route
      items: [
        {
          label: 'INICIO',
          command: () => { this.navigateTo('/varta/'); },
        },
        {
          label: 'VARTA SILVER',
          command: () => { this.navigateTo('/varta/silver'); },
        },
        {
          label: 'VARTA BLUE',
          command: () => { this.navigateTo('/varta/blue'); },
        },
        {
          label: 'VARTA BLACK',
          command: () => { this.navigateTo('/varta/black'); },
        },
        {
          label: 'VARTA MINERÍA',
          command: () => { this.navigateTo('/varta/mineria'); },
        },
        {
          label: 'SOBRE NOSOTROS',
          command: () => { this.navigateTo('/SobreNosotros/'); },
        },
        {
          label: 'CONTACTO',
          command: () => { this.navigateTo('/varta/contact'); },
        },
      ],
    };
  },
  methods: {
    redirectToVarta() {
      this.$router.push('/varta/');
      this.currentRoute = '/varta/';
    },
    navigateTo(path) {
      if (path === '/SobreNosotros/') {
        window.location.href = 'https://electro3.com.ar/#/SobreNosotros';
        return;
      }
      this.$router.push(path);
      this.currentRoute = path;
    },
  },
};
</script>

<style scoped>
.menu-container {
  margin: 0 auto;
  width: 80%;
}

.custom-menubar {
  --p-menubar-item-color: #7b7b7d;
  --p-menubar-item-focus-background: transparent;
  --p-menubar-item-focus-color: rgb(10, 10, 10);
  border: none;
  box-shadow: none;
  background: transparent;
}

img {
  width: 15rem;
  height: 6rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-container {
    width: 100%;
  }
}
</style>
